<template>
    <div class="page2">
        <Row class='content'>
            <Col span="7">
<!--              <div class="circlePie" id="circlePie">-->
<!--                <canvas id="main" width="500" height="500"></canvas>-->
<!--                <canvas id="dot"></canvas>-->
<!--              </div>-->

              <div class="left-top-list">
                <div class="left-top">
                  <span class='title'><span class="title-text">各项目总数据</span></span>
                  <span class="angle1"></span>
                  <span class="angle2"></span>
                  <span class="angle3"></span>
                  <span class="angle4"></span>
                  <dataTotal></dataTotal>
                </div>
                <div class="bottom-list">
                  <div class="bottom">
                    <span class='title'><span class="title-text">{{picsTitle}}</span></span>
                    <span class="angle1"></span>
                    <span class="angle2"></span>
                    <span class="angle3"></span>
                    <span class="angle4"></span>
                    <double-line ref="chart9" id="bottom_1"></double-line>
                  </div>
                </div>
              </div>

            </Col>
            <Col span="10" class="centerBox">
              <div class="description">
                <span class='title'><span class="title-text">{{picsTitle}}</span></span>
                <span class="angle1"></span>
                <span class="angle2"></span>
                <span class="angle3"></span>
                <span class="angle4"></span>
                <div class="describeList">
                  <span>{{describeList[chooseIndex]}}</span>
                </div>
              </div>
              <div class="chinaMap">
                <china-map ref="chinaMap"></china-map>
              </div>
            </Col>
            <Col span="7">
              <div class="list">
                  <div class="right">
                      <span class='title'><span class="title-text">{{picsTitle}}</span></span>
                      <span class="angle1"></span>
                      <span class="angle2"></span>
                      <span class="angle3"></span>
                      <span class="angle4"></span>
                      <div class="chart-67">
                          <bar-chart ref="chart3" id="left_3" :config="configData2"></bar-chart>
                      </div>
<!--                      <div class="chart-32">-->
<!--                          <radar-chart ref="chart4" id="left_4" :data="officeRadarData"></radar-chart>-->
<!--                      </div>-->
                  </div>
              </div>
                <div class="list">
                    <div class="right">
                        <span class='title'><span class="title-text">{{picsTitle}}</span></span>
                        <span class="angle1"></span>
                        <span class="angle2"></span>
                        <span class="angle3"></span>
                        <span class="angle4"></span>
<!--                        <div class="chart-32">-->
<!--                            <radar-chart ref="chart7" id="right_3" :data="momentsRadarData"></radar-chart>-->
<!--                        </div>-->
                        <div class="chart-67">
                            <single-area-chart ref="chart8" id="right_4"></single-area-chart>
                        </div>
                    </div>
                </div>
                <div class="list-bottom-pics">
                  <div class="right-pics">
                    <span class='title'><span class="title-text">{{picsTitle}}</span></span>
                    <span class="angle1"></span>
                    <span class="angle2"></span>
                    <span class="angle3"></span>
                    <span class="angle4"></span>
                    <workPics ref="workPic" :isBig="false"></workPics>
                  </div>
                  <div class="right-pics">
                    <span class='title'><span class="title-text">荣誉图片</span></span>
                    <span class="angle1"></span>
                    <span class="angle2"></span>
                    <span class="angle3"></span>
                    <span class="angle4"></span>
                    <honorPics ref="honor" :isLarge="false"></honorPics>
                  </div>
                </div>
            </Col>
        </Row>
      <div class="tabNav">
        <div v-for="(item,index) in workName" :key="index" @click="chooseNav(item,index)" :class="index==chooseIndex?'on_workname':'workname'">{{item}}</div>
      </div>
    </div>
</template>

<script>
// const areaChart = ()=> import('./components/areaChart');
// const radarChart = () => import('./components/radar');
const barChart = () => import('./components/page2/barChart');
const chinaMap  = () => import('./components/chinaMap');
// const doubleBarChart = ()=> import('./components/page2/doubleBarChart');
const singleAreaChart = () => import('./components/page2/singleAreaChart');
const doubleLine  = () => import('./components/page2/doubleLine');
const workPics = () => import('./components/page2/workPictures');
const dataTotal =() => import('./components/page2/data-total');
const honorPics = ()=> import('./components/page2/HonoraryPics.vue');
// const threeBarChart = () => import('./components/page2/threeBarChart');
// const pieChart = () => import('./components/page2/pieChart');
// const doubleBars = () => import('./components/page2/doubleBars');

export default {
    name: 'page2',
    props: ['selectRangeDate'],
    components: {
        // radarChart,
        barChart,
        singleAreaChart,
        doubleLine,
        workPics, //项目图片
        // pieChart,
        dataTotal,
        honorPics,
        chinaMap, //湖南地图
    },
  data() {
        return {
          //tabNav子菜单
          workName:['守望驿站','温暖包','净水计划','壹乐园-音乐教室','壹乐园-音乐运动汇','儿童服务站','一个鸡蛋','XIN益佰"一个鸡蛋的暴走"','妇幼守望者公益岗'],
          //右下角图片标题
          picsTitle:'',
          h:-1, //滚动高度
          timer:null, //定时器
          describeList:[
              '在湖南城乡社区建立常态化妇女儿童关爱服务点，设立公益性专岗进行常态化运营，通过搭建平台、链接资源、开发标准化的服务体系，同时联合各界社会爱心力量组建医疗、司法和心理咨询三支公益服务队，定期在驿站开展健康检查及咨询等常态化活动，探索“医社联合”的在地化服务模式，为妇女儿童的生理和心理健康保驾护航，目前已建成5个守望驿站。',
              '温暖包是针对高寒凝冻地区儿童过冬的应急生活和心里关怀需求而设计的公益计划。长沙群英会携手壹基金联合省内社会组织、志愿者、媒体、爱心企业和社会公众，参与到温暖包的筹款、分装、转运、发放、探访等环节中，搭建社会力量参与公益的平台，通过本地社会组织更好的回应困境儿童的需求，帮助儿童获得有保障、有尊严的生活。',
              '净水计划项目以保障农村地区儿童的饮水安全和提高儿童健康卫生习惯为目标，项目通过为农村地区的项目学校提供净水设备，结合儿童卫生健康教育，提升农村地区儿童卫生健康意识，促进儿童养成良好的卫生习惯。2012年，长沙群英会作为壹基金净水计划湖南省枢纽机构，至今已执行净水计划项目九年时间。',
              '壹乐园音乐教室项目以儿童发展为核心，通过音乐教室内部环境改造、音乐教学器材配备、音乐教师专业技能培训、音乐课程开发，以及音乐主题活动推广，提高乡村学校的音乐教育质量，促进儿童的情感发展乡村音乐教育质量，促进儿童的情感发展和社会交往，帮助乡村儿童在快乐中发展潜能。',
              '壹乐园运动汇项目以儿童发展为核心，通过儿童游乐设施和功能运动场建设、体育课程与活动开发，以及体育主题活动推广，提高乡村学校的体育教育质量，促进儿童的身体发育、情感发展和社会交往，帮助乡村儿童在快乐中发展潜能。2021年，长沙群英会执行壹乐园运动汇项目在湖南攸县落地，为攸县6所乡村学校建设价值72万元的多功能运动场。',
              '壹基金儿童服务站项目以儿童发展和保护为核心，通过支持本地社会组织在农村地区、城市流动人口聚集地区及灾后地区建立安全友好的儿童活动空间，依托学校和家庭之外的“空间+专职+服务”模式，为乡村留守儿童和城乡流动儿童供课外游戏活动、安全卫生教育和社会心理支持等服务，缓解农村留守儿童及城乡流动儿童因监护不周、陪伴缺失和教育资源欠佳等原因导致的故意及意外伤害频发、心理健康问题突出及多元化教育机会匮乏的问题，保障儿童基本权利，助力儿童身心发展。并探索常态下社区儿童服务平台的搭建，以支持并提升社会组织参与社区儿童服务的专业性与可持续性；提升公众和政策对留守流动儿童议题的关注和支持。',
              '2020年12月，上海联劝公益基金会携手群英会在湖南落地“一个鸡蛋”项目，希望通过学期内每天给乡村幼儿一个鸡蛋，改变他们的膳食结构，以及营养摄入量低下的现状，保证他们的身体和智力均衡发展。',
              'XIN益佰-“一个鸡蛋的暴走”项目旨在通过建档评估、物资帮扶、 走访陪伴、 个案服务、 团体活动、 家庭监护支持、 政社联动活动、公众参与活动等专业手法，为溆浦县350名因家庭监护缺失或监护不当的儿童构建社会支持网络，帮助困境儿童减轻和摆脱困境，保障困境儿童的基本权利，让困境儿童健康快乐成长、平等发展。',
              '由长沙群英会聘请公益专职人员驻点长沙市妇幼保健院，整合志愿+医疗服务力量在长沙市妇幼保健院打造全社会体系公益服务岗，为医院就医人员开展常态化的志愿服务，同时通过整合院内公益医疗资源，引进社会公益资源，搭建公益医疗资源共享平台，为医院就医人员提供精准的公益资源对接，协调医患关系。推动医疗系统公益领域发展的实践与创新，打造共建共治共享的医企社共同体。'
          ],
          resdata:[
            {
              name: '长沙市',
              value: 1,
              choiceIndex:1,
              list:[
                {
                  title: '守望驿站',
                  soprtcount: '776',
                  serviceCount: '13152',
                  volunteerCount: '252',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '温暖包',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '净水计划',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '壹乐园——音乐教室',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '壹乐园——运动汇',
                  soprtcount: '1',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '1',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '儿童服务站',
                  soprtcount: '25471',
                  serviceCount: '300843',
                  volunteerCount: '4478',
                  schoolCount: '0 ',
                  money: '0 ',
                  attent: '90',
                },
                {
                  title: '一个鸡蛋',
                  soprtcount: '0',
                  serviceCount: '2063',
                  volunteerCount: '8',
                  schoolCount: '8',
                  money: '0',
                  attent: '2',
                },
                {
                  title: 'XIN益佰一个鸡蛋的暴走',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '妇幼守望者公益岗',
                  soprtcount: '107',
                  serviceCount: '1238',
                  volunteerCount: '150',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
              ]
            },
            {
              name:'株洲市',
              value: 1,
              choiceIndex:1,
              list:[
                {
                  title: '守望驿站',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '温暖包',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '净水计划',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '壹乐园——音乐教室',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '壹乐园——运动汇',
                  soprtcount: '6',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '6',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '儿童服务站',
                  soprtcount: '1981',
                  serviceCount: '23399',
                  volunteerCount: '348',
                  schoolCount: '0 ',
                  money: '0 ',
                  attent: '7',
                },
                {
                  title: '一个鸡蛋',
                  soprtcount: '0',
                  serviceCount: '380',
                  volunteerCount: '1',
                  schoolCount: '1',
                  money: '0',
                  attent: '1',
                },
                {
                  title: 'XIN益佰 一个鸡蛋的暴走',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '妇幼守望者公益岗',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
              ]
            },
            {
              name:'湘潭市',
              value:1,
              choiceIndex:1,
              list:[
                {
                  title: '守望驿站',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '温暖包',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '净水计划',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '壹乐园——音乐教室',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '壹乐园——运动汇',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '儿童服务站',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0 ',
                  money: '0 ',
                  attent: '0',
                },
                {
                  title: '一个鸡蛋',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: 'XIN益佰 一个鸡蛋的暴走',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '妇幼守望者公益岗',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
              ]
            },
            {
              name:'衡阳市',
              value: 1,
              choiceIndex: 1,
              list:[
                {
                  title: '守望驿站',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '温暖包',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '净水计划',
                  soprtcount: '1',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '1',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '壹乐园——音乐教室',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '壹乐园——运动汇',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '儿童服务站',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0 ',
                  money: '0 ',
                  attent: '0',
                },
                {
                  title: '一个鸡蛋',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: 'XIN益佰 一个鸡蛋的暴走',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '妇幼守望者公益岗',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
              ]
            },
            {
              name:'常德市',
              value: 1,
              choiceIndex: 1,
              list:[
                {
                  title: '守望驿站',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '温暖包',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '净水计划',
                  soprtcount: '14',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '14',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '壹乐园——音乐教室',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '壹乐园——运动汇',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '儿童服务站',
                  soprtcount: '1698',
                  serviceCount: '20057',
                  volunteerCount: '298',
                  schoolCount: '0 ',
                  money: '0 ',
                  attent: '6',
                },
                {
                  title: '一个鸡蛋',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: 'XIN益佰 一个鸡蛋的暴走',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '妇幼守望者公益岗',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
              ]
            },
            {
              name:'益阳市',
              value: 1,
              choiceIndex: 1,
              list:[
                {
                  title: '守望驿站',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '温暖包',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '净水计划',
                  soprtcount: '40',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '40',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '壹乐园——音乐教室',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '壹乐园——运动汇',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '儿童服务站',
                  soprtcount: '1132',
                  serviceCount: '13371',
                  volunteerCount: '198',
                  schoolCount: '0 ',
                  money: '0 ',
                  attent: '4',
                },
                {
                  title: '一个鸡蛋',
                  soprtcount: '0',
                  serviceCount: '133',
                  volunteerCount: '2',
                  schoolCount: '2',
                  money: '0',
                  attent: '1',
                },
                {
                  title: 'XIN益佰 一个鸡蛋的暴走',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '妇幼守望者公益岗',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
              ]
            },
            {
              name:'娄底市',
              value: 1,
              choiceIndex: 1,
              list:[
                {
                  title: '守望驿站',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '温暖包',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '净水计划',
                  soprtcount: '2',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '2',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '壹乐园——音乐教室',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '壹乐园——运动汇',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '儿童服务站',
                  soprtcount: '284',
                  serviceCount: '3342',
                  volunteerCount: '50',
                  schoolCount: '0 ',
                  money: '0 ',
                  attent: '1',
                },
                {
                  title: '一个鸡蛋',
                  soprtcount: '0',
                  serviceCount: '85',
                  volunteerCount: '1',
                  schoolCount: '1',
                  money: '0',
                  attent: '1',
                },
                {
                  title: 'XIN益佰 一个鸡蛋的暴走',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '妇幼守望者公益岗',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
              ]
            },
            {
              name:'郴州市',
              value: 1,
              choiceIndex: 1,
              list:[
                {
                  title: '守望驿站',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '温暖包',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '净水计划',
                  soprtcount: '20',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '20',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '壹乐园——音乐教室',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '壹乐园——运动汇',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '儿童服务站',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0 ',
                  money: '0 ',
                  attent: '0',
                },
                {
                  title: '一个鸡蛋',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: 'XIN益佰 一个鸡蛋的暴走',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '妇幼守望者公益岗',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
              ]
            },
            {
              name:'永州市',
              value:1,
              choiceIndex: 1,
              list:[
                {
                  title: '守望驿站',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '温暖包',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '净水计划',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '壹乐园——音乐教室',
                  soprtcount: '9',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '9',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '壹乐园——运动汇',
                  soprtcount: '2',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '2',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '儿童服务站',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0 ',
                  money: '0 ',
                  attent: '0',
                },
                {
                  title: '一个鸡蛋',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: 'XIN益佰 一个鸡蛋的暴走',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '妇幼守望者公益岗',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
              ]
            },
            {
              name:'怀化市',
              value: 1,
              choiceIndex: 1,
              list:[
                {
                  title: '守望驿站',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '温暖包',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '净水计划',
                  soprtcount: '1',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '1',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '壹乐园——音乐教室',
                  soprtcount: '9',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '9',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '壹乐园——运动汇',
                  soprtcount: '13',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '13',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '儿童服务站',
                  soprtcount: '1698',
                  serviceCount: '20056',
                  volunteerCount: '298',
                  schoolCount: '0 ',
                  money: '0 ',
                  attent: '6',
                },
                {
                  title: '一个鸡蛋',
                  soprtcount: '0',
                  serviceCount: '1375',
                  volunteerCount: '14',
                  schoolCount: '14',
                  money: '0',
                  attent: '2',
                },
                {
                  title: 'XIN益佰 一个鸡蛋的暴走',
                  soprtcount: '40',
                  serviceCount: '350',
                  volunteerCount: '200',
                  schoolCount: '39',
                  money: '105',
                  attent: '3',
                },
                {
                  title: '妇幼守望者公益岗',
                  soprtcount: '0',
                  serviceCount: '0',
                  schoolCount: '0',
                  volunteerCount: '0',
                  money: '0',
                  attent: '0',
                },
              ]
            },
            {
              name:'湘西土家族苗族自治州',
              value: 1,
              choiceIndex: 1,
              list:[
                {
                  title: '守望驿站',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '温暖包',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '净水计划',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '壹乐园——音乐教室',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '壹乐园——运动汇',
                  soprtcount: '4',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '4',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '儿童服务站',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0 ',
                  money: '0 ',
                  attent: '0',
                },
                {
                  title: '一个鸡蛋',
                  soprtcount: '0',
                  serviceCount: '1611',
                  volunteerCount: '4',
                  schoolCount: '4',
                  money: '0',
                  attent: '1',
                },
                {
                  title: 'XIN益佰 一个鸡蛋的暴走',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '妇幼守望者公益岗',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
              ]
            },
            {
              name:'张家界市',
              value: 1,
              choiceIndex: 1,
              list:[
                {
                  title: '守望驿站',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '温暖包',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '净水计划',
                  soprtcount: '35',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '35',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '壹乐园——音乐教室',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '壹乐园——运动汇',
                  soprtcount: '8',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '8',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '儿童服务站',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0 ',
                  money: '0 ',
                  attent: '0',
                },
                {
                  title: '一个鸡蛋',
                  soprtcount: '0',
                  serviceCount: '323',
                  volunteerCount: '1',
                  schoolCount: '1',
                  money: '0',
                  attent: '1',
                },
                {
                  title: 'XIN益佰 一个鸡蛋的暴走',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '妇幼守望者公益岗',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
              ]
            },
            {
              name:'岳阳市',
              value: 1,
              choiceIndex: 1,
              list:[
                {
                  title: '守望驿站',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '温暖包',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '净水计划',
                  soprtcount: '4',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '4',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '壹乐园——音乐教室',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '壹乐园——运动汇',
                  soprtcount: '37',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '37',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '儿童服务站',
                  soprtcount: '6792',
                  serviceCount: '80225',
                  volunteerCount: '1194',
                  schoolCount: '0 ',
                  money: '0 ',
                  attent: '24',
                },
                {
                  title: '一个鸡蛋',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: 'XIN益佰 一个鸡蛋的暴走',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '妇幼守望者公益岗',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
              ]
            },
            {
              name:'邵阳市',
              value: 1,
              choiceIndex: 1,
              list:[
                {
                  title: '守望驿站',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '温暖包',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '净水计划',
                  soprtcount: '33',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '33',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '壹乐园——音乐教室',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '壹乐园——运动汇',
                  soprtcount: '10',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '10',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '儿童服务站',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0 ',
                  money: '0 ',
                  attent: '0',
                },
                {
                  title: '一个鸡蛋',
                  soprtcount: '0',
                  serviceCount: '204',
                  volunteerCount: '5',
                  schoolCount: '5',
                  money: '0',
                  attent: '1',
                },
                {
                  title: 'XIN益佰 一个鸡蛋的暴走',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '妇幼守望者公益岗',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
              ]
            },
          ],
            everyPer: 0,
            xOffset: 0,
            circle: {
                x: 250,
                y: 250,
                radius: 218
            },
            //项目图片数组
            workPicsList:[
              {
                name:'守望驿站项目照片',
                picsList:[
                  require('@/assets/women_children/watchPost/watch1.jpg'),
                  require('@/assets/women_children/watchPost/watch2.jpg'),
                  require('@/assets/women_children/watchPost/watch3.jpg'),
                  require('@/assets/women_children/watchPost/watch4.jpg'),
                  require('@/assets/women_children/watchPost/watch5.jpg'),
                  require('@/assets/women_children/watchPost/watch6.jpg'),
                  require('@/assets/women_children/watchPost/watch7.jpg'),
                  require('@/assets/women_children/watchPost/watch8.jpg'),
                  require('@/assets/women_children/watchPost/watch9.jpg'),
                  require('@/assets/women_children/watchPost/watch10.jpg'),
                ]
              },
              {
                name:'温暖包',
                picsList:[
                  require('@/assets/women_children/warmPack/warm1.jpg'),
                  require('@/assets/women_children/warmPack/warm2.jpg'),
                  require('@/assets/women_children/warmPack/warm3.jpg'),
                ]
              },
              {
                name:'净水计划',
                picsList:[
                  require('@/assets/women_children/waterPlan/1.jpg'),
                  require('@/assets/women_children/waterPlan/2.jpg'),
                  require('@/assets/women_children/waterPlan/3.jpg'),
                  require('@/assets/women_children/waterPlan/4.jpg'),
                ]
              },
              {
                name:'壹乐园-音乐教室',
                picsList:[]
              },
              {
                name:'壹乐园-音乐运动汇',
                picsList:[
                  require('@/assets/women_children/oneParadise/paradiseSport/1.jpg'),
                  require('@/assets/women_children/oneParadise/paradiseSport/2.jpg'),
                  require('@/assets/women_children/oneParadise/paradiseSport/3.jpg'),
                  require('@/assets/women_children/oneParadise/paradiseSport/4.jpg'),
                  require('@/assets/women_children/oneParadise/paradiseSport/5.jpg'),
                  require('@/assets/women_children/oneParadise/paradiseSport/6.jpg'),
                ]
              },
              {
                name:'儿童服务站优质照片',
                picsList:[
                  require('@/assets/women_children/childrenService/pics1/1.jpg'),
                  require('@/assets/women_children/childrenService/pics1/2.jpg'),
                  require('@/assets/women_children/childrenService/pics1/3.jpg'),
                  require('@/assets/women_children/childrenService/pics1/4.jpg'),
                  require('@/assets/women_children/childrenService/pics1/5.jpg'),
                  require('@/assets/women_children/childrenService/pics1/6.jpg'),
                  require('@/assets/women_children/childrenService/pics1/7.jpg'),
                  require('@/assets/women_children/childrenService/pics1/8.jpg'),
                  require('@/assets/women_children/childrenService/pics1/9.jpg'),
                  require('@/assets/women_children/childrenService/pics1/10.jpg'),
                  require('@/assets/women_children/childrenService/pics1/11.jpg'),
                  require('@/assets/women_children/childrenService/pics1/12.jpg'),
                  require('@/assets/women_children/childrenService/pics1/13.jpg'),
                  require('@/assets/women_children/childrenService/pics1/14.jpg'),
                  require('@/assets/women_children/childrenService/pics1/15.jpg'),
                  require('@/assets/women_children/childrenService/pics1/16.jpg'),
                  require('@/assets/women_children/childrenService/pics1/17.jpg'),
                  require('@/assets/women_children/childrenService/pics1/18.jpg'),
                  require('@/assets/women_children/childrenService/pics1/19.jpg'),
                  require('@/assets/women_children/childrenService/pics1/20.jpg'),
                  require('@/assets/women_children/childrenService/pics1/21.jpg'),
                  require('@/assets/women_children/childrenService/pics1/22.jpg'),
                  require('@/assets/women_children/childrenService/pics1/23.jpg'),
                  require('@/assets/women_children/childrenService/pics1/24.jpg'),
                  require('@/assets/women_children/childrenService/pics1/25.jpg'),
                  require('@/assets/women_children/childrenService/pics1/26.jpg'),
                  require('@/assets/women_children/childrenService/pics1/27.jpg'),
                  require('@/assets/women_children/childrenService/pics1/28.jpg'),
                  require('@/assets/women_children/childrenService/pics1/29.jpg'),
                  require('@/assets/women_children/childrenService/pics1/30.jpg'),
                  require('@/assets/women_children/childrenService/pics1/31.jpg'),
                  require('@/assets/women_children/childrenService/pics1/32.jpg'),
                  require('@/assets/women_children/childrenService/pics1/33.jpg'),
                  require('@/assets/women_children/childrenService/pics1/34.jpg'),
                  require('@/assets/women_children/childrenService/pics1/35.jpg'),
                  require('@/assets/women_children/childrenService/picsWeekend/weekend1.jpg'),
                  require('@/assets/women_children/childrenService/picsWeekend/weekend2.jpg'),
                  require('@/assets/women_children/childrenService/picsWeekend/weekend3.jpg'),
                  require('@/assets/women_children/childrenService/picsWeekend/weekend4.jpg'),
                  require('@/assets/women_children/childrenService/picsWeekend/weekend5.jpg'),
                  require('@/assets/women_children/childrenService/picsWeekend/weekend6.jpg'),
                  require('@/assets/women_children/childrenService/picsWeekend/weekend7.jpg'),
                  require('@/assets/women_children/childrenService/picsWeekend/weekend8.jpg'),
                  require('@/assets/women_children/childrenService/picsWeekend/weekend9.jpg'),
                  require('@/assets/women_children/childrenService/picsWeekend/weekend10.jpg'),
                  require('@/assets/women_children/childrenService/picsWeekend/weekend11.jpg'),
                  require('@/assets/women_children/childrenService/picsWeekend/weekend12.jpg'),
                  require('@/assets/women_children/childrenService/picsWeekend/weekend13.jpg'),
                  require('@/assets/women_children/childrenService/picsWeekend/weekend14.png'),
                  require('@/assets/women_children/childrenService/picsWeekend/weekend15.jpg'),
                  require('@/assets/women_children/childrenService/picsWeekend/weekend16.jpg'),
                  require('@/assets/women_children/childrenService/picsWeekend/weekend17.jpg'),
                  require('@/assets/women_children/childrenService/picsWeekend/weekend18.jpg'),
                  require('@/assets/women_children/childrenService/picsWeekend/weekend19.jpg'),
                  require('@/assets/women_children/childrenService/picsWeekend/weekend20.jpg'),
                  require('@/assets/women_children/childrenService/picsWeekend/weekend21.jpg'),
                  require('@/assets/women_children/childrenService/picsWeekend/weekend22.jpg'),
                  require('@/assets/women_children/childrenService/picsWeekend/weekend23.jpg'),
                  require('@/assets/women_children/childrenService/picsWeekend/weekend24.jpg'),
                  require('@/assets/women_children/childrenService/picsWeekend2/1.jpg'),
                  require('@/assets/women_children/childrenService/picsWeekend2/2.jpg'),
                  require('@/assets/women_children/childrenService/picsWeekend2/3.jpg'),
                  require('@/assets/women_children/childrenService/picsWeekend2/4.jpg'),
                  require('@/assets/women_children/childrenService/picsWeekend2/5.jpg'),
                  require('@/assets/women_children/childrenService/picsWeekend2/6.jpg'),
                  require('@/assets/women_children/childrenService/picsWeekend2/7.jpg'),
                  require('@/assets/women_children/childrenService/picsWeekend2/8.jpg'),
                  require('@/assets/women_children/childrenService/picsWeekend2/9.jpg'),
                  require('@/assets/women_children/childrenService/picsWeekend2/10.jpg'),
                  require('@/assets/women_children/childrenService/picsWeekend2/11.jpg'),
                  require('@/assets/women_children/childrenService/picsWeekend2/12.jpg'),
                  require('@/assets/women_children/childrenService/picsWeekend2/13.jpg'),
                  require('@/assets/women_children/childrenService/picsWeekend2/14.png'),
                  require('@/assets/women_children/childrenService/picsWeekend2/15.jpg'),
                  require('@/assets/women_children/childrenService/picsWeekend2/16.jpg'),
                  require('@/assets/women_children/childrenService/picsWeekend2/17.jpg'),
                  require('@/assets/women_children/childrenService/picsWeekend2/18.jpg'),
                  require('@/assets/women_children/childrenService/picsWeekend2/19.jpg'),
                  require('@/assets/women_children/childrenService/picsWeekend2/20.jpg'),
                  require('@/assets/women_children/childrenService/picsWeekend2/21.jpg'),
                  require('@/assets/women_children/childrenService/picsWeekend2/22.jpg'),
                  require('@/assets/women_children/childrenService/picsWeekend2/23.jpg'),
                  require('@/assets/women_children/childrenService/picsWeekend2/24.jpg'),
                  require('@/assets/women_children/childrenService/picsWeekend2/25.jpg'),
                  require('@/assets/women_children/childrenService/picsWeekend2/26.jpg'),
                  require('@/assets/women_children/childrenService/picsWeekend2/27.jpg'),
                  require('@/assets/women_children/childrenService/picsWeekend2/28.jpg'),
                  require('@/assets/women_children/childrenService/picsWeekend2/29.jpg'),
                  require('@/assets/women_children/childrenService/picsWeekend2/30.jpg'),
                  require('@/assets/women_children/childrenService/picsWeekend2/31.jpg'),
                  require('@/assets/women_children/childrenService/pics1/1.jpg'),
                  require('@/assets/women_children/childrenService/pics1/2.jpg'),
                  require('@/assets/women_children/childrenService/pics1/3.jpg'),
                  require('@/assets/women_children/childrenService/pics1/4.jpg'),
                  require('@/assets/women_children/childrenService/pics1/5.jpg'),
                  require('@/assets/women_children/childrenService/pics1/6.jpg'),
                  require('@/assets/women_children/childrenService/pics1/7.jpg'),
                  require('@/assets/women_children/childrenService/pics1/8.jpg'),
                  require('@/assets/women_children/childrenService/pics1/9.jpg'),
                  require('@/assets/women_children/childrenService/pics1/10.jpg'),
                  require('@/assets/women_children/childrenService/pics1/11.jpg'),
                  require('@/assets/women_children/childrenService/pics1/12.jpg'),
                  require('@/assets/women_children/childrenService/pics1/13.jpg'),
                  require('@/assets/women_children/childrenService/pics1/14.jpg'),
                  require('@/assets/women_children/childrenService/pics1/15.jpg'),
                  require('@/assets/women_children/childrenService/pics1/16.jpg'),
                  require('@/assets/women_children/childrenService/pics1/17.jpg'),
                  require('@/assets/women_children/childrenService/pics1/18.jpg'),
                  require('@/assets/women_children/childrenService/pics1/19.jpg'),
                  require('@/assets/women_children/childrenService/pics1/20.jpg'),
                  require('@/assets/women_children/childrenService/pics1/21.jpg'),
                  require('@/assets/women_children/childrenService/pics1/22.jpg'),
                  require('@/assets/women_children/childrenService/pics1/23.jpg'),
                  require('@/assets/women_children/childrenService/pics1/24.jpg'),
                  require('@/assets/women_children/childrenService/pics1/25.jpg'),
                  require('@/assets/women_children/childrenService/pics1/26.jpg'),
                  require('@/assets/women_children/childrenService/pics1/27.jpg'),
                  require('@/assets/women_children/childrenService/pics1/28.jpg'),
                  require('@/assets/women_children/childrenService/pics1/29.jpg'),
                  require('@/assets/women_children/childrenService/pics1/30.jpg'),
                  require('@/assets/women_children/childrenService/pics1/31.jpg'),
                  require('@/assets/women_children/childrenService/pics1/33.jpg'),
                  require('@/assets/women_children/childrenService/pics1/34.jpg'),
                  require('@/assets/women_children/childrenService/pics1/35.jpg'),
                ]
              },
              {
                name:'一个鸡蛋项目传播图片',
                picsList:[
                  require('@/assets/women_children/eggSpread/egg-spread1.jpg'),
                  require('@/assets/women_children/eggSpread/egg-spread2.jpg'),
                  require('@/assets/women_children/eggSpread/egg-spread3.jpg'),
                  require('@/assets/women_children/eggSpread/egg-spread4.jpg'),
                  require('@/assets/women_children/eggSpread/egg-spread5.jpg'),
                  require('@/assets/women_children/eggSpread/egg-spread6.jpg'),
                  require('@/assets/women_children/eggSpread/egg-spread7.jpg'),
                  require('@/assets/women_children/eggSpread/egg-spread8.jpg'),
                  require('@/assets/women_children/eggSpread/egg-spread9.jpg'),
                  require('@/assets/women_children/eggSpread/egg-spread10.jpg'),
                  require('@/assets/women_children/eggSpread/egg-spread11.jpg'),
                  require('@/assets/women_children//eggSpread/egg-spread12.jpg'),
                ]
              },
              {
              name:'一个鸡蛋的暴走项目照片',
              picsList:[
                require('@/assets/women_children/eggWork/egg1.jpg'),
                require('@/assets/women_children/eggWork/egg3.jpg'),
                require('@/assets/women_children/eggWork/egg4.jpg'),
                require('@/assets/women_children/eggWork/egg5.jpg'),
                require('@/assets/women_children/eggWork/egg6.jpg'),
                require('@/assets/women_children/eggWork/egg7.jpg'),
                require('@/assets/women_children/eggWork/egg8.jpg'),
                require('@/assets/women_children/eggWork/egg9.jpg'),
                require('@/assets/women_children/eggWork/egg10.jpg'),
                require('@/assets/women_children/eggWork/egg11.jpg'),
                require('@/assets/women_children/eggWork/egg12.jpg'),
                require('@/assets/women_children/eggWork/egg13.jpg'),
              ]
            },
              {
              name:'妇幼守望者公益岗',
              picsList:[
                require('@/assets/women_children/womenChildWelfare/1.jpg'),
                require('@/assets/women_children/womenChildWelfare/2.jpg'),
                require('@/assets/women_children/womenChildWelfare/3.jpg'),
                require('@/assets/women_children/womenChildWelfare/4.jpg'),
                require('@/assets/women_children/womenChildWelfare/5.jpg'),
                require('@/assets/women_children/womenChildWelfare/6.jpg'),
                require('@/assets/women_children/womenChildWelfare/7.jpg'),
                require('@/assets/women_children/womenChildWelfare/8.jpg'),
                require('@/assets/women_children/womenChildWelfare/9.jpg'),
                require('@/assets/women_children/womenChildWelfare/10.jpg'),
                require('@/assets/women_children/womenChildWelfare/11.jpg'),
                require('@/assets/women_children/womenChildWelfare/12.jpg'),
                require('@/assets/women_children/womenChildWelfare/13.jpg'),
                require('@/assets/women_children/womenChildWelfare/14.jpg'),
              ]
            },

          ],
            chooseIndex:0,
            // title: ['累计活动场次:150', '累计服务人数:30000', '累计志愿者人数:461', '累计覆盖学校数量:150', '累计投入款物（万元）:678','累计社会组织参与数:7',],
            configData2: {
                data: [213, 190, 137, 99, 63, 196, 248, 212, 248, 112]
            },
            warea: {x: 250, y: 250, max: 700},
            dots: [],
            resizeFn: null,
            animationFrame1:null,
            animationFrame2: null,
            centerBox: {
                width: 0,
                height: 0
            }
        }
    },
  methods: {
      // 点击子菜单(导航栏)选择
      chooseNav(item,index){
        this.chooseIndex = index;
        this.picsTitle = item; //标题随着nav导航选项而变化
        //动态获取图片数据
        this.$refs.workPic.workPicsList2 = this.workPicsList[index].picsList;
        this.$refs.workPic.isChoose = !this.$refs.workPic.isChoose; //点击菜单，右下角项目图片重新渲染滑动
        this.$refs.workPic.loading=false; //加载中
        // 荣誉图片
        // this.$refs.honor.honoraryPicsList = this.workPicsList[index].picsList;
        this.$refs.honor.isChoice = !this.$refs.honor.isChoice; //点击菜单，右下角荣誉图片重新渲染滑动
        this.$refs.honor.load=false; //加载中
        //选择导航栏 把index对应传给choiceIndex 从而获取不同数据
        for (var i=0;i<this.resdata.length;i++){
          this.resdata[i].choiceIndex=index;

          //动态计算每个城市的每个项目总和  (目的：若项目数据都为0，湖南地图显示不同颜色)
            let totalValue = Number(this.resdata[i].list[index].attent) + Number(this.resdata[i].list[index].money)
                + Number(this.resdata[i].list[index].schoolCount) + Number(this.resdata[i].list[index].serviceCount)
                + Number(this.resdata[i].list[index].soprtcount) + Number(this.resdata[i].list[index].volunteerCount)
            this.resdata[i].value = totalValue;
        }

        this.$refs.chinaMap.seriesData = this.resdata
        this.$refs.chinaMap.setMap(); //刷新地图数据
        //每次点击导航选项将之前数据置空 再进行下面操作
        this.$refs.chart9.soprtList = [];
        this.$refs.chart9.serviceList = [];
        this.$refs.chart3.volunteerList =[];
        this.$refs.chart3.schoolList=[];
        this.$refs.chart8.moneyList=[];
        this.$refs.chart8.socialList=[];
        //各城市的数据遍历获取
        this.resdata.forEach(it=>{
          this.$refs.chart9.soprtList.push(it.list[index].soprtcount); //左下角活动场次
          this.$refs.chart9.serviceList.push(it.list[index].serviceCount) //左下角服务人次
          this.$refs.chart3.volunteerList.push(it.list[index].volunteerCount) //右上角志愿者人数
          this.$refs.chart3.schoolList.push(it.list[index].schoolCount) //右上角覆盖学校数量
          this.$refs.chart8.moneyList.push(it.list[index].money) //右中投入款物
          this.$refs.chart8.socialList.push(it.list[index].attent) //右中社会组织参与数
        })
        //刷新子组件数据
        this.$refs.chart9.setChart();
        this.$refs.chart3.setChart();
        this.$refs.chart8.setChart();
      },
  },
  mounted() {
    // this.centerBox = {
        //     width: document.querySelector('#circlePie').offsetWidth,
        //     height: document.querySelector('#circlePie').offsetHeight
        // }
        // for (let i = 0; i < 200; i++) {
        //     // 随机200个运动的圆点
        //     let x = Math.random() * this.centerBox.width; // 随机的x偏移量
        //     let y = Math.random() * this.centerBox.height; // 随机y轴偏移量
        //     let xa = Math.random() * 2 - 1; // x轴运动速度
        //     let ya = Math.random() * 2 - 1; // y轴运动速度
        //     this.dots.push({
        //         x: x,
        //         y: y,
        //         xa: xa,
        //         ya: ya,
        //         // 两个圆点之间需要连线的距离
        //         max: 40
        //     })
        // }
        // this.act();
        // this.drawDot();
        // this.resizeFn = this.$debounce(()=> {
        //     // 通过捕获系统的onresize事件触发我们需要执行的事件
        //     this.centerBox = {
        //         width: document.querySelector('#circlePie').offsetWidth,
        //         height: document.querySelector('#circlePie').offsetHeight
        //     }
        //     for (let i = 1; i < 13; i++) {
        //         this.$refs['chart' + i].setChart();
        //     }
        // }, 500)
        // window.addEventListener('resize', this.resizeFn)
        setTimeout(()=>{
          this.chooseNav(this.workName[0],0);
        },100)
  },
    beforeDestroy() {
        window.removeEventListener('resize', this.resizeFn)
        window.cancelAnimationFrame(this.animationFrame1)
        window.cancelAnimationFrame(this.animationFrame2)
    }
}
</script>

<style lang="less" scoped>
.page2 {
    height: 100%;
    width: 100%;
    padding: 8px 20px 20px;
    background: #03044A;
    overflow: hidden;
    position: relative;
  .tabNav{
    width: auto;
    margin: 0 auto;
    height: 4.5%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin-bottom: 1%;
    position: absolute;
    bottom: -2%;
    left: 25%;
    //justify-content: space-around;
    .workname{
      color:#fff;
      font-size: 15px;
      width: auto;
      height: 100%;
      line-height: 38px;
      min-width: 90px;
      padding: 0 10px;
      text-align: center;
      background-color: #4b92e1;
      border-right:1px solid #fff;
      font-family: "华文细黑", Courier New, Courier, monospace;
    }
    .on_workname{
      color:#4b92e1;
      font-size: 15px;
      width: auto;
      height: 100%;
      line-height: 38px;
      min-width: 90px;
      padding: 0 10px;
      text-align: center;
      border-radius: 3px;
      background-color: #fff;
      font-family: "宋体", "Times New Roman", Times, serif;
    }
  }

    .content {
        height: 62%;

        .ivu-col {
            height: 100%;
        }
      .centerBox{
        height:120%;
        .chinaMap{
          height: 95%;
        }
        .description{
          height: 34%;
          padding-bottom: 2%;
          margin-bottom: -1%;
        }
        .describeList{
          text-indent: 3%;
          display: flex;
          height: 96%;
          width: 98%;
          margin: 2% auto;
          align-items: center;
          color:rgb(167,198,235);
          line-height: 27px;
          font-size: 15px;
          font-family: "华文细黑", Courier New, Courier, monospace;
          span{
            width: 100%;
            height: auto;
            max-height: 100%;
          }
        }
        .describeList::-webkit-scrollbar {/*滚动条整体样式*/
          width: 4px;     /*高宽分别对应横竖滚动条的尺寸*/
          height: 1px;
        }
        .describeList::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
          border-radius: 10px;
          -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
          background: #a1b3dc;
        }
        .describeList::-webkit-scrollbar-track {/*滚动条里面轨道*/
          -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
          border-radius: 10px;
          background: rgb(8,25,79);
        }
      }

        .circlePie {
            height: 100%;
            padding: 0 0 40px;
            text-align: center;
            position: relative;
            margin-right: 15px;

            canvas {
                position: absolute;
                left: 50%;
                top: 0;
                transform: translate(-50%, 0);
            }

            #dot {
                background: rgba(0, 0, 0, 0);
            }
        }

      .list-bottom-pics{
        display: flex;
      }

        .list,.list-bottom-pics {
            height: 52.3%;
            .left, .right, .center,.right-pics {
                width: 98%;
                height: 92%;
                border: 1px solid #0D2451;
                position: relative;
                margin-left: 15px;

                #left1, #left2, #left3, #right1, #right2, #right3, #center2 {
                    height: 100%;
                }

              .chart-68{
                width: 65%;
                height: 100%;
                float: left;
              }

                .chart-67 {
                    width: 100%;
                    height: 100%;
                    float: left;
                }

                .chart-32 {
                    width: 32%;
                    height: 100%;
                    float: left;
                }
            }
        }
    }
  .bottom,.right,.right-pics,.left-top,.description {
    width: 100%;
    border: 1px solid #0D2451;
    position: relative;
    box-sizing: border-box;
    //background: rgb(8, 25, 79);

    .title {
      position: absolute;
      display: inline-block;
      color: #6EDDF1;
      border: 2px solid #6EDDF1;
      height: 18px;
      padding: 0 2px;
      left: 50%;
      transform: translate(-50%, -50%);

      .title-text {
        position: relative;
        font-size: 16px;
        background: #09102E;
        display: inline-block;
        padding: 0 4px;
        white-space: nowrap;
        transform: translateY(-5px);
        font-family: "宋体", "Times New Roman", Times, serif;
      }
    }
  }

  .right-pics{
    width: 50% !important;
  }

  .left-top-list{
    height: 90%;
    //margin-top: 30px;
    width: 97%;
    .left-top{
      height: 100%;
    }
  }

        .bottom-list {
                margin-top: 30px;
                height: 63%;
                width: 100%;
                .bottom {
                    width: 100%;
                    height: 100%;
                    border: 1px solid #0D2451;
                    position: relative;
                }
            }

            .right-bottom {
                width: 100%;
                padding-right: 0;

                .bottom1 {
                    width: 100%;
                }
            }

}
</style>
